import { render, staticRenderFns } from "./add-product-broken-dialog.vue?vue&type=template&id=04adfa15&scoped=true"
import script from "./add-product-broken-dialog.ts?vue&type=script&lang=ts&external"
export * from "./add-product-broken-dialog.ts?vue&type=script&lang=ts&external"
import style0 from "./add-product-broken-dialog.scoped.scss?vue&type=style&index=0&id=04adfa15&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04adfa15",
  null
  
)

export default component.exports