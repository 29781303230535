import { roleService } from '@/api';
import { PermissionResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Message, Tree } from 'element-ui';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  name: 'permission-config'
})
export default class PermissionConfig extends Vue {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false, required: true }) public syncedVisible!: boolean;
  @Prop({ type: Object, required: true })
  public configParams!: { roleName: string; roleId: number };

  public title = 'role.permissionConfig';
  public resourceIdList: Array<number> = [];
  public treeOptions: Array<PermissionResource> = [];
  public submitLoading = false;

  public defaultTreeProps = {
    children: 'children',
    label: 'name'
  };

  public treeControl = {
    /**
     * 全部展开
     */
    fold: true,
    /**
     * 选中全部
     */
    selectAll: false
  };

  public dialogOpen(): void {
    Promise.all([this.getPermissionResource(), this.getPermissionByRoleId()])
      .then(resArr => {
        this.treeOptions = resArr[0] || [];
        const leafs: Array<number> = this.getAllLeaf(resArr[1]);
        this.$nextTick(() => {
          this.echo(leafs)
        });
      })
      .catch(error => {
        messageError(error);
      });
  }
  public echo(leafs: Array<number>): void {
    leafs.forEach(key => {
      const currentNode = (this.$refs.permissionTree as Tree).getNode(key);
      // 统一处理子节点为相同的勾选状态
      if ([2, 3, 4].includes(currentNode.level)) {
        (this.$refs.permissionTree as Tree).setChecked(currentNode, true, true);
        // 子节点只要被选中父节点就被选中
        this.selectedParent(currentNode);
      } else if ([1].includes(currentNode.level) && currentNode.childNodes.length === 0) {
        (this.$refs.permissionTree as Tree).setChecked(currentNode, true, true);
      }
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    this.resetForm();
  }

  public async onSubmit(): Promise<void> {
    this.setLoading(true);
    try {
      const selectedKeys = (this.$refs.permissionTree as Tree)
        .getHalfCheckedKeys()
        .concat((this.$refs.permissionTree as Tree).getCheckedKeys());
        if (selectedKeys.length === 0) {
          Message.error(translation('role.selectPermission'));
          return;
        }
      await roleService.configurationPermission({
        roleId: this.configParams.roleId,
        resourcesIdList: selectedKeys
      });
      Message.success(translation('operationRes.editSuccess'));
      this.closeDialog();
    } catch (error) {
      messageError(error);
    } finally {
      this.setLoading(false);
    }
  }

  public foldChange(value: boolean): void {
    (this.$refs.permissionTree as Tree).store._getAllNodes().forEach(x => {
      x.expanded = value;
    });
  }

  public checkAllChange(value: boolean): void {
    if (!value) {
      // 清空
      (this.$refs.permissionTree as Tree).setCheckedKeys([]);
    } else {
      // 全选
      const nodeKey: Array<number> = this.getAllLeaf(this.treeOptions);
      (this.$refs.permissionTree as Tree).setCheckedKeys(nodeKey as any);
    }
  }
  

  public resetForm(): void {
    this.treeControl = {
      fold: true,
      selectAll: false
    };
    this.resourceIdList = [];
  }

  private setLoading(value: boolean): void {
    this.submitLoading = value;
  }

  private closeDialog(): void {
    this.syncedVisible = false;
  }

  private async getPermissionResource(): Promise<Array<PermissionResource>> {
    return roleService.getAllUsingPermissionResource();
  }

  private async getPermissionByRoleId(): Promise<Array<PermissionResource>> {
    return roleService.getPermissionByRoleId(this.configParams.roleId);
  }

  /**
   * 获取树的所有叶子节点
   * @param tree 树
   * @returns
   */
  private getAllLeaf(tree: Array<PermissionResource>): Array<number> {
    const result: Array<number> = [];
    function getLeaf(data: Array<PermissionResource>): void {
      data.forEach(item => {
        result.push(item.id);
        getLeaf(item.children);
      });
    }
    getLeaf(tree);
    return result;
  }

  private clickDeal(currentObj: any, treeStatus: any): void {
    // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
    const selected = treeStatus.checkedKeys.indexOf(currentObj.id); // -1未选中
    // 选中
    if (selected !== -1) {
      // 统一处理子节点为相同的勾选状态
      this.uniteChildSame(currentObj, true);
    } else {
      // 未选中 处理子节点全部未选中
      if (currentObj.children.length !== 0) {
        this.uniteChildSame(currentObj, false);
      }
    }
    // 处理父级选中状态
    this.selectedParent(currentObj);
  }
  // 统一处理子节点为相同的勾选状态
  private uniteChildSame(treeList: any, isSelected: any): void {
    (this.$refs.permissionTree as Tree).setChecked(treeList.id, isSelected, true);
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < treeList.children.length; i++) {
      this.uniteChildSame(treeList.children[i], isSelected);
    }
  }
  // 统一处理父节点为选中
  private selectedParent(currentObj: any): void {
    // 菜单页面父及联动， 按钮不予父及联动，一级菜单下的按钮与父及联动
    if (
      (currentObj.scope === 2 || currentObj?.data?.scope === 2) &&
      !(
        ['supplier_dashboard', 'supplier_queryScheme'].includes(currentObj.parentId) ||
        ['supplier_dashboard', 'supplier_queryScheme'].includes(currentObj.data.parentId)
      )
    ) {
      return;
    }
    const currentNode = (this.$refs.permissionTree as Tree).getNode(currentObj);
    this.setNodeStatus(currentNode);
  }
  // 设置节点
  private setNodeStatus(currentNode: any): void {
    if (currentNode.parent!.key !== undefined) {
      const checkNum =
        currentNode.parent?.childNodes.filter((x: any) => x.checked === true || x.indeterminate === true)
          .length ?? 0;
      const currentNodeLength = currentNode.parent?.childNodes.length ?? 0;
      // 选中
      if (checkNum > 0 && checkNum === currentNodeLength) {
        (this.$refs.permissionTree as Tree).setChecked(currentNode.parent, true, true);
      }
      // 半选中
      if (checkNum > 0 && checkNum < currentNodeLength) {
        this.$set(currentNode.parent!, 'indeterminate', true);
      }
      // 取消选中
      if (checkNum === 0) {
        (this.$refs.permissionTree as Tree).setChecked(currentNode.parent, false, false);
      }
      this.selectedParent(currentNode.parent);
    }
  }
}
