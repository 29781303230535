import productionOrderProductService from '@/api/production-management/production-order-product';
import {
  ChargeUnitEnum,
  OperationTypeEnum,
  OrderProductTypeEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  ProcessActionEnum,
  ProductionProductStatusEnum,
  ProjectItemTypeEnum,
  PlatformPrepressStatusEnum
} from '@/resource/enum';
import { ProductionOrderProductResource } from '@/resource/model';
import { isNullOrUndefinedForBaseType, messageError, translation, dateFormat } from '@/utils';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  name: 'receipt-detail-dialog',
  components: {}
})
export default class ReceiptDetailDialog extends Vue {
  /**
   * 产品信息
   */
  @Prop({ required: true }) public product!: ProductionOrderProductResource;

  public viewProduct: ProductionOrderProductResource | null = null;

  /**
   * 弹窗显示控制
   */
  @PropSync('visible', { required: true }) public syncedVisible!: boolean;
  /**
   * 产品详情tab激活页
   */
  public activeName = 'base';

  public get isConsumeRemake(): string {
    if (isNullOrUndefinedForBaseType(this.product.attritionFlag)) {
      return '';
    }
    return translation(`common.${this.product.attritionFlag === 0 ? 'no' : 'yes'}`);
  }

  /**
   * 获取产品类型国际化key
   * @param type 产品类型
   * @returns
   */
  public getProductTypeI18nKey(type: number): string {
    return 'orderProductType.' + OrderProductTypeEnum[type];
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: ProductionProductStatusEnum): string {
    return productionOrderProductService.getStatusI18Key(status);
  }

  /**
   * 获取计量单位国际化key
   * @param unit 计量单位
   * @returns
   */
  public getChargeUnitI18nKey(priceUnit: ChargeUnitEnum): string {
    return 'chargeUnit.' + ChargeUnitEnum[priceUnit];
  }

  /**
   * 获取工序国际化key
   * @param type 工序
   * @returns
   */
  public getProcessTypeI18nKey(operation: OperationTypeEnum): string {
    return 'operationTypeEnum.' + OperationTypeEnum[operation];
  }

  /**
   * 获取工序操作国际化key
   * @param action 操作类型
   * @returns
   */
  public getProcessActionI18nKey(action: ProcessActionEnum): string {
    return 'processActionEnum.' + ProcessActionEnum[action];
  }

  public handleOpen(): void {
    productionOrderProductService
      .getById(this.product.id)
      .then(res => {
        this.viewProduct = res;
        this.viewProduct.screenArea = (
          (this.viewProduct!.finishWidth / 1000) *
          (this.viewProduct!.finishHeight / 1000)
        ).toFixed(3);
        this.viewProduct.screenRate = (
          this.viewProduct!.finishWidth / this.viewProduct!.finishHeight
        ).toFixed(3);
      })
      .catch(error => {
        messageError(error);
      });
  }

  public formatterPrepressCrafts(data: ProductionOrderProductResource): string {
    if (data.itemType === ProjectItemTypeEnum.changeColor) {
      return translation(`prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[data.prepressCrafts]}`);
    }
    return translation(`prepressLayoutCrafts.${PrepressLayoutCraftsEnum[data.prepressCrafts]}`);
  }

  public getPrepressStatusValue(): string {
    if (!this.viewProduct!.makeUserName) {
      return translation(
        'platformPrepressStatus.' + PlatformPrepressStatusEnum[this.viewProduct!.prepressStatus]
      );
    }

    if (!PlatformPrepressStatusEnum[this.viewProduct!.prepressStatus]) {
      return `${translation('common.unKnownStatus')}[${this.viewProduct!.makeUserName}]`;
    }

    if(PlatformPrepressStatusEnum.completed === this.viewProduct!.prepressStatus) {
      return `${translation(
        'platformPrepressStatus.' + PlatformPrepressStatusEnum[this.viewProduct!.prepressStatus]
      )}[${this.viewProduct!.makeUserName}][${dateFormat(this.viewProduct!.makeStateTime)}]`
    }

    return `${translation(
      'platformPrepressStatus.' + PlatformPrepressStatusEnum[this.viewProduct!.prepressStatus]
    )}[${this.viewProduct!.makeUserName}]`;
  }

  public flagName(flag: 1 | 0): string {
    return flag ? translation('orderProduct.flagOpen') : translation('orderProduct.flagClose');
  }
}
