import { consumeService } from '@/api';
import { ChargeUnitEnum, OperationTypeEnum } from '@/resource/enum';
import { ProductBrokenResource, ProductionOrderProductResource } from '@/resource/model';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { translation, messageError, mathRound6 } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { DialogMixin } from '@/mixins/dialog';

@Component({
  name: 'add-product-borken-dialog'
})
export default class AddProductBrokenDialog extends mixins(DialogMixin) {
  /**
   * 显示控制
   */
  @PropSync('visible') public syncedVisible!: boolean;

  /**
   * 传入数据
   */
  @Prop({ required: true }) public rowData!: ProductionOrderProductResource;

  @Prop({ required: true }) public type!: 'all' | 'prepress' | 'print' | 'backend';

  /**
   * 提交loading状态
   */
  public submitLoading = false;

  /**
   * 耗损上报数据
   */
  public formData: Partial<ProductBrokenResource> = {
    orderItemId: this.rowData.id,
    itemCode: this.rowData.code,
    fallbackOperation: undefined,
    calcUnit: ChargeUnitEnum.squareMeter,
    attritionCount: 1,
    dutyReason: '',
    dutyUser: '',
    attritionArea: this.defaultAreaForNotSquareMeter()
  };

  public formRules = {
    rollbackOperation: [
      {
        required: true,
        validator: (rule: Object, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('productBroken.selectBrokenProcess')));
            return;
          }

          callback();
        },
        trigger: 'change'
      }
    ],
    attritionCount: [
      {
        required: true,
        validator: (rule: Object, value: number, callback: Function): void => {
          if (!value || value <= 0) {
            callback(new Error(translation('productBroken.inputCount')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    attritionArea: [
      {
        required: true,
        validator: (rule: Object, value: number, callback: Function): void => {
          if (!value || value < 0) {
            callback(new Error(translation('productBroken.inputBrokenArea')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    calcUnit: [
      {
        required: true,
        validator: (rule: Object, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('productBroken.selectUnit')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    dutyReason: [
      {
        required: true,
        validator: (rule: Object, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('productBroken.inputDutyReason')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
    // dutyUser: [
    //   {
    //     required: true,
    //     validator: (rule: Object, value: string, callback: Function): void => {
    //       if (!value) {
    //         callback(new Error(translation('productBroken.inputDutyUser')));
    //         return;
    //       }
    //       callback();
    //     },
    //     trigger: 'blur'
    //   }
    // ]
  };

  public get isShowCountInput(): boolean {
    return this.formData.calcUnit !== ChargeUnitEnum.squareMeter;
  }

  public get attritionArea(): string {
    return `${translation('productBroken.brokenArea')}：${this.formData.attritionArea!.toFixed(6)}㎡`;
  }

  public get showFallbackProcess(): boolean {
    return this.type !== 'all';
  }

  public getRollbackOperationList(): Array<any> {
    const processTypeList: Array<any> = [];
    for (const key in OperationTypeEnum) {
      if (isNaN(Number(key)) || typeof Number(key) !== 'number') {
        continue;
      }

      if (this.rowData.processType < Number(key)) {
        continue;
      }

      if (Number(key) === 1 && !this.rowData.prepressFlag) {
        continue;
      }

      if (Number(key) === 2 && !this.rowData.printingFlag) {
        continue;
      }

      if (Number(key) === 3 && !this.rowData.backendFlag) {
        continue;
      }

      processTypeList.push({
        label: translation('operationTypeEnum.' + OperationTypeEnum[key]),
        value: Number(key)
      });
    }

    return processTypeList;
  }

  /**
   * 获取单位列表
   */
  public getUnitList(): Array<any> {
    const unitList: Array<any> = [];

    for (const key in ChargeUnitEnum) {
      if (!isNaN(Number(key)) && typeof Number(key) === 'number') {
        unitList.push({
          label: translation(`chargeUnit.${ChargeUnitEnum[key]}`),
          value: Number(key)
        });
      }
    }

    return unitList;
  }

  /**
   * 提交
   */
  public handleSubmit(): void {
    (this.$refs.form as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.type === 'all') {
          this.formData.fallbackOperation = undefined;
        }
        await consumeService.post(this.formData as any);
        Message.success(translation('operationRes.addSuccess'));
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  @Watch('formData.attritionCount')
  private calAttritionArea(): void {
    this.formData.attritionArea = mathRound6(
      this.formData.attritionCount! * (this.rowData.finishWidth / 1000) * (this.rowData.finishHeight / 1000)
    );
  }

  private defaultAreaForNotSquareMeter(): number {
    return mathRound6((this.rowData.finishWidth / 1000) * (this.rowData.finishHeight / 1000) * 1);
  }
}
